<template>
  <div class="login">
    <div class="main">
      <!-- <div class="title">数字乡村信息化建设平台</div> -->
      <div class="title">登录</div>
      <div class="item">
        <el-input v-model="loginForm.username" class="login-input" type="text" auto-complete="off" placeholder="请输入账号">
          <i slot="prefix" style="display: flex;align-items: center;">
            <img class="header-icon" src="../assets/images/user.png" alt="" />
          </i>
        </el-input>
      </div>
      <div class="item">
        <el-input v-model="loginForm.md5password" class="login-input" type="password" auto-complete="off" placeholder="请输入密码">
          <i slot="prefix" style="display: flex;align-items: center;">
            <img class="header-icon" src="../assets/images/password.png" alt="" />
          </i>
        </el-input>
      </div>
     <!--  <div class="item">
        <el-input v-model="loginForm.code" class="login-code" auto-complete="off" placeholder="请输入验证码">
          <i slot="prefix" style="display: flex;align-items: center;">
            <img class="header-icon" src="../assets/images/code.png" alt="" />
          </i>
        </el-input>
        <img :src="codeUrl" @click="getCode" class="login-code-img" />
      </div> -->
      <div class="item" style="margin-top:1.2vw;">
        <el-checkbox v-model="loginForm.rememberMe" style="float:left" size="medium">记住密码</el-checkbox>
      </div>

      <div class="item">
        <button @click="btnOK()" class="btn-ok">登录</button>

      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'md5';
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt.js'
export default {
  data() {
    return {
      loginForm: { username: '', md5password: '', rememberMe: false },
      codeUrl: ''
    };
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode() {
      this.$api.login.captchaImage().then(res => {
        this.codeUrl = "data:image/gif;base64," + res.data.img;
        this.loginForm.uuid = res.data.uuid;
      });
    },
    getCookie() {
      let that = this;
      const username = Cookies.get("htmlaccount");
      const password = Cookies.get("htmlpassword");
      const rememberMe = Cookies.get('htmlrememberMe')

      that.loginForm.username = username === undefined ? that.loginForm.username : username;
      that.loginForm.md5password = password === undefined ? that.loginForm.md5password : decrypt(password);
      that.loginForm.rememberMe = rememberMe === undefined ? that : Boolean(rememberMe);

    },
    btnOK() {
      let that = this;
      if (that.loginForm.username == null || that.loginForm.username == "") {
        that.$message.error("用户名不能为空");
      }
      if (that.loginForm.md5password == null || that.loginForm.md5password == "") {
        that.$message.error("密码不能为空");
      }
     /*  if (that.loginForm.code == null || that.loginForm.code == "") {
        that.$message.error("验证码不能为空");
      } */
      that.loginForm.password = md5(that.loginForm.md5password);
      that.$api.login.login(that.loginForm).then(res => {
        if (this.loginForm.rememberMe) {
          Cookies.set("htmlaccount", this.loginForm.username, { expires: 30 });
          Cookies.set("htmlpassword", encrypt(this.loginForm.md5password), { expires: 30 });
          Cookies.set('htmlrememberMe', this.loginForm.rememberMe, { expires: 30 });
        } else {
          Cookies.remove("htmlaccount");
          Cookies.remove("htmlpassword");
          Cookies.remove('htmlrememberMe');
        }
        if (res.status === 200 && res.data.code === 200) {
          that.$message.success("登录成功");
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("nickName", res.data.nickName);
          console.log(res)
          this.$router.push({ name: "index" });
        } else {
          this.getCode();
        }
      }).catch(() => {
        this.getCode();
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  background-image: url('../assets/images/loginback.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
  .head {
    float: left;
    width: 100%;
    background: #f0f6f9;
    text-align: center;
    height: 3.3vw;
    font-size: 1.3vw;
    font-family: FZLTZHK--GBK1-0, FZLTZHK--GBK1;
    font-weight: normal;
    color: #131415;
    line-height: 3.3vw;
  }
  .main {
    float: right;
    width: 26vw;
    height: 26vw;
    margin-right: 13.8vw;
    margin-top: 12.9vw;
    background-image: url('../assets/images/loginform.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .title {
      width: 100%;
      height: 2vw;
      font-size: 2vw;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 2vw;
      margin-top: 1.7vw;
      float: left;
      text-align: center;
    }
    .item {
      float: left;
      width: 100%;
      margin-top: 1.7vw;
      .header-icon {
        margin-right: 4px;
        width: 1.4vw;
        height: 1.4vw;
        float: left;
        margin-left: 0.5vw;
        margin-top: 0.7vw;
      }

      .login-input {
        width: 21vw;
        float: left;
        margin-left: 2.5vw;
      }

      /deep/.el-input__inner {
        border: 1px solid #00c0ff;
        background-color: #00315b;
        height: 2.9vw;
        float: left;
        padding-left: 2.5vw;
        font-size: 1vw;
        color: #ccd6de;
      }
      .login-code {
        width: 12.5vw;
        margin-left: 2.5vw;
        float: left;
      }
      .login-code-img {
        width: 8vw;
        height: 2.7vw;
        float: left;
        margin-left: 0.5vw;
      }

      /deep/.el-checkbox__inner {
        width: 0.9vw;
        height: 0.9vw;
        margin-top: -0.5vw;
        border: 1px solid #00b8ff;
        background-color: #00315b;
        margin-left: 2.5vw;
      }
      /deep/.el-checkbox__label {
        font-size: 0.8vw;
        font-family: FZLTXHK--GBK1-0, FZLTXHK--GBK1;
        color: #fff;
        margin-top: 0.3vw;
      }
      /deep/.el-checkbox__inner::after {
        height: 0.7vw;
        left: 0.2vw;
        width: 0.3vw;
        top: 0;
      }
    }
  }
  .btn-ok {
    float: left;
    width: 21vw;
    height: 2.9vw;
    margin-left: 2.5vw;
    background: #31cfdd;
    border-radius: 0.3vw;
    font-size: 1.4vw;
    line-height: 2.9vw;
    text-align: center;
    border: none;
    color: #fff;
    cursor: pointer;
  }
}
</style>